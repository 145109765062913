const ADMIN = 'admin';
const ALL_USER = 'all_user';
const CONTROL_BODY_USER = 'control_body_user';
const USER = 'user';
const OWNER = 'owner';
const MEMBER = 'member';
const SUPER_ADMIN = 'super_admin';
const CONTROL_BODY_OWNER = 'control_body_owner';
const OPERATION_MANAGER = 'operation_manager';
const MANAGER = 'manager';
const TEACHER = 'teacher';
const MAIN_CONTACT = 'main_contact';
const PARTNER = 'partner';

export {
  ADMIN,
  ALL_USER,
  CONTROL_BODY_USER,
  USER,
  OWNER,
  MEMBER,
  SUPER_ADMIN,
  CONTROL_BODY_OWNER,
  OPERATION_MANAGER,
  MANAGER,
  TEACHER,
  MAIN_CONTACT,
  PARTNER,
};
