import React, { useCallback, useEffect, useState } from 'react';
import { t } from 'i18next';
import { debounce } from 'throttle-debounce';
import Pagination from 'components/base-components/Pagination/Pagination';
import { useSearchParams } from 'react-router-dom';
import Button from 'components/base-components/Button';
import Input from 'components/base-components/Input';
import { useGetInvoiceJobsQuery } from 'api/invoices';
import PropTypes from 'prop-types';
import Icon from 'components/base-components/Icon';
import InvoiceFilterModal from 'components/invoices/filter/invoice-filter-modal';
import SidePanel from 'components/base-components/SidePanel/SidePanel';
import CreateJob from 'components/invoices/Form/create-job/index.component';
import InvoiceJobsTable from 'components/invoices/shared/jobs-table.component';

const InvoiceJobListComponent = ({ currentTab }) => {
  const [searchInput, setSearchInput] = useState('');
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [isSidepanelOpen, setIsSidepanelOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams({
    q: '',
    page: 1,
  });

  const currentPage = parseInt(searchParams.get('page'));
  const searchQuery = searchParams.get('q');

  useEffect(() => {
    setSearchInput(searchQuery);
  }, []);

  const invoiceParams = {
    q: searchQuery,
    page: currentPage,
  };

  const setSearchQueryParams = useCallback(params => {
    Object.keys(params).forEach(key => {
      searchParams.set(key, params[key]);
    });
    setSearchParams(searchParams);
  }, []);

  const setSearchQueryInParams = debounce(500, value => {
    setSearchQueryParams({ q: value, tab: currentTab, page: 1 });
  });

  const onSearch = value => {
    setSearchInput(value);
    setSearchQueryInParams(value);
  };

  const { data: invoiceJobsCollection = {}, isFetching } = useGetInvoiceJobsQuery(invoiceParams);
  const invoiceJobs = invoiceJobsCollection?.collection || [];

  return (
    <>
      <div className="invoice__header--wrapper">
        <div className="invoice-list__search-tab">
          <Input
            className="invoice-list__search"
            placeholder={t('invoice.search_invoice')}
            preIcon={<Icon size="small" name="search" />}
            size="tiny"
            value={searchInput}
            onChange={onSearch}
          />
          <Button
            onClick={() => setIsFilterVisible(true)}
            label={
              <span className="invoice-list__filter-button">
                <Icon fill="none" name="filter" />
                <span>{t('shared.action.filter')}</span>
              </span>
            }
            size="small"
          />
        </div>
        <div className="invoice__create-button">
          <Button
            type="success"
            size="small"
            label={t('invoice.create_new_job')}
            onClick={() => setIsSidepanelOpen(true)}
          />
        </div>
      </div>

      <SidePanel
        isOpened={isSidepanelOpen}
        title={t('invoice.create_new_job')}
        body={<CreateJob setIsSidepanelOpen={setIsSidepanelOpen} />}
        onClose={() => setIsSidepanelOpen(false)}
        onClick={() => setIsSidepanelOpen(false)}
      />

      <InvoiceJobsTable invoiceJobs={invoiceJobs} isFetching={isFetching} />
      <div className="invoice-list__pagination">
        <Pagination
          onPaginationClick={newPage => {
            setSearchQueryParams({ page: newPage, tab: currentTab });
          }}
          totalPages={
            invoiceJobsCollection && parseInt(invoiceJobsCollection.pagination?.totalPages, 10)
          }
          currentPage={currentPage}
          firstText={t('pagination.first')}
          lastText={t('pagination.last')}
          nextText={t('pagination.next')}
          previousText={t('pagination.prev')}
        />
      </div>
      <InvoiceFilterModal
        setFilterQueryParams={() => {}}
        isFilterVisible={isFilterVisible}
        setIsFilterVisible={setIsFilterVisible}
      />
    </>
  );
};

InvoiceJobListComponent.defaultProps = {
  currentTab: null,
  enableBulkAction: false,
};

InvoiceJobListComponent.propTypes = {
  currentTab: PropTypes.string,
  enableBulkAction: PropTypes.bool,
};

export default InvoiceJobListComponent;
