import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { useGetOrganizationAssignmentsQuery, useGetLegalFormOptionsQuery } from 'api/organizations';
import Button from 'components/base-components/Button';
import useCustomNavigate from 'hooks/useCustomNavigate';
import { OPERATION_MANAGER, MAIN_CONTACT } from 'utils/users-role';
import ContentLoaderPlaceholder from 'components/base-components/ContentLoader';
import Card from 'components/base-components/Card';
import ContactUser from './contact-user.component';

const LegalForm = ({ organisation, isAdmin }) => {
  const navigate = useCustomNavigate();

  const { data: legalFormOptions = [] } = useGetLegalFormOptionsQuery();
  const organisationId = organisation?.id;
  const { data: assignments = [], isFetching } = useGetOrganizationAssignmentsQuery(
    {
      organizationId: organisationId,
      'roles[]': [OPERATION_MANAGER, MAIN_CONTACT],
    },
    { skip: !organisationId },
  );

  const legalForm = legalFormOptions.find(option => option.value === organisation?.legalForm);

  return (
    <>
      {assignments.length > 0 && (
        <Card className="organisation-overview__card">
          {isFetching ? (
            <ContentLoaderPlaceholder numberOfLines={4} showBackground={false} />
          ) : (
            <>
              <span className="organisation-overview__card-title">
                {`${t('legal_form.select_box.form_label')} - ${legalForm?.label}`}
              </span>
              <div className="organisation-overview__content-wrapper">
                {assignments.map((assignment, idx) => (
                  <ContactUser key={idx} assignment={assignment} />
                ))}
              </div>
              {isAdmin && (
                <Button
                  className="organisation-overview__content--button"
                  label={t('organisation_view.overview.attributes.legal_form_edit_button')}
                  size="small"
                  onClick={() => navigate.openLegalForm(organisation?.id)}
                />
              )}
            </>
          )}
        </Card>
      )}
    </>
  );
};

LegalForm.propTypes = {
  organisation: PropTypes.shape().isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default LegalForm;
