import { useLocation } from 'react-router-dom';

const IsPublicRoute = () => {
  const params = useLocation();
  const checkSubRoute = '/public';

  return params.pathname.includes(checkSubRoute);
};

export default IsPublicRoute;
