import PropTypes from 'prop-types';
import CreateForm from '../components/organisations/create/index.component';

const CreateOrganisation = ({ department }) => {
  return <CreateForm department={department} />;
};

CreateOrganisation.defaultProps = {
  department: null,
};

CreateOrganisation.propTypes = {
  department: PropTypes.string,
};

export default CreateOrganisation;
