import React from 'react';
import { useParams } from 'react-router-dom';
import Card from 'components/base-components/Card';
import LoginLogo from 'assets/bio_logo.png';
import { t } from 'i18next';
import IsPublicRoute from 'utils/is-public-route';
import { Header } from 'components/base-components/Typography';
import EditForm from '../components/organisations/edit/index.component';

const EditOrganisation = () => {
  const { id } = useParams();
  const isPublic = IsPublicRoute();

  return (
    <>
      {isPublic && (
        <Card>
          <div className="flex-with-center-align">
            <img src={LoginLogo} alt="BioSuisseLogo" height={50} width={50} />
            <Header className="margin-left-20" level={6} weight="boldest">
              {t('public_organisation.new_organisation')}
            </Header>
          </div>
        </Card>
      )}
      <EditForm orgID={id} />
    </>
  );
};

export default EditOrganisation;
