import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Card from 'components/base-components/Card';
import ContentLoaderPlaceholder from 'components/base-components/ContentLoader';
import OrganizationName from 'components/organisations/organization-name';
import UserName from 'components/users/user-name.component';
import OverviewContent from '../overview-content.component';

const OverviewHeader = ({ isFetching, organisation, owners }) => (
  <div className="col-12 col-bleed organisation-overview__card">
    <Card>
      {isFetching ? (
        <ContentLoaderPlaceholder numberOfLines={2} showBackground={false} />
      ) : (
        <>
          <div className="col-1">
            <OverviewContent
              label={t('organisation.bio_id')}
              value={organisation?.bioId}
              noMargin={true}
            />
          </div>
          <div className="col-2">
            <OverviewContent
              label={t('organisation_type.uid')}
              value={organisation?.uid}
              noMargin={true}
            />
          </div>
          <div className="col-2">
            <OverviewContent
              label={t('organisation_view.overview.registered_name')}
              value={organisation?.name}
              noMargin={true}
            />
          </div>
          <div className="col-1">
            <OverviewContent
              label={t('organisation_view.overview.industry')}
              value={organisation?.industry && t(`organisation.industry.${organisation.industry}`)}
              noMargin={true}
            />
          </div>
          <div className="col-2">
            <div className="organisation-overview__header">
              {t('organisation_view.overview.owner')}
            </div>
            <div className="organisation-overview__text">
              {owners.length > 0
                ? owners.map((owner, index) => (
                    <>
                      <UserName userData={owner} />
                      {index !== owners.length - 1 ? ', ' : ''}
                    </>
                  ))
                : t('common.not_applicable')}
            </div>
          </div>
          <div className="col-2">
            <div className="organisation-overview__header">
              {t('organisation_view.overview.parent_organisation')}
            </div>
            <div className="organisation-overview__text organisation-overview__text-link">
              <OrganizationName organizationId={organisation?.parentId} />
            </div>
          </div>
          <div className="col-2">
            <div className="organisation-overview__header">
              {t('organisation_view.overview.education_user')}
            </div>
            <div className="organisation-overview__text organisation-overview__text-link">
              {organisation?.educationAssigneeUserId ? (
                <UserName userID={organisation?.educationAssigneeUserId} />
              ) : (
                t('common.not_applicable')
              )}
            </div>
          </div>
        </>
      )}
    </Card>
  </div>
);

OverviewHeader.defaultProps = {
  isFetching: true,
  organisation: {},
  owners: [],
};

OverviewHeader.propTypes = {
  isFetching: PropTypes.bool,
  organisation: PropTypes.shape(),
  owners: PropTypes.arrayOf(PropTypes.shape()),
};

export default OverviewHeader;
