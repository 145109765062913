import arrayMutators from 'final-form-arrays';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/base-components/Table';
import { t } from 'i18next';
import React, { useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import RadioWithoutCheck from 'components/base-components/RadioWithoutCheck';
import { useParams, useSearchParams } from 'react-router-dom';
import Button from 'components/base-components/Button';
import {
  useGetOrganizationQuery,
  useUpdateOrganizationAssignmentsMutation,
} from 'api/organizations';
import { useGetUsersQuery } from 'api/users';
import WarningModal from 'components/shared-components/modal/warning';
import useCustomNavigate from 'hooks/useCustomNavigate';
import SubmitModal from 'components/shared-components/modal/submit-modal';
import CheckBox from 'components/base-components/CheckBox';
import {
  MAIN_CONTACT,
  MANAGER,
  MEMBER,
  OPERATION_MANAGER,
  OWNER,
  PARTNER,
  TEACHER,
} from 'utils/users-role';
import Pagination from 'components/base-components/Pagination';
import { isNonEmptyObject } from 'utils/check-object-length';
import { PRODUCER } from 'utils/organisation-industries';
import { removeEmptyFromArray } from 'utils/array-cleanup';

const getRolesColumns = isProducerOrganisation => {
  const ROLES = isProducerOrganisation
    ? [PARTNER, OPERATION_MANAGER, MAIN_CONTACT, MANAGER, MEMBER, TEACHER]
    : [OWNER, MEMBER];

  return ROLES;
};

const UserRolesTableRow = ({ fieldName, user, mainContactId, isProducerOrganisation }) => {
  const { id } = useParams();
  const DISABLED_ROLES = isProducerOrganisation ? [PARTNER, OPERATION_MANAGER] : [OWNER];

  const userRoles = useMemo(() => {
    return (user?.assignments || []).reduce((roles, { organizationId, role }) => {
      if (organizationId?.toString() === id) {
        roles.push(role);
      }
      return roles;
    }, []);
  }, [user.assignments, id]);

  return (
    <>
      <TableRow>
        <Field
          name={`${fieldName}.userId`}
          type="hidden"
          initialValue={user.id}
          component="input"
        />
        <TableData>{user.name}</TableData>
        {getRolesColumns(isProducerOrganisation).map(role => (
          <TableData align="center">
            {role === MAIN_CONTACT ? (
              <Field name="main_contact" type="radio" value={user.id} initialValue={mainContactId}>
                {({ input }) => (
                  <div className="organisation__form-preferred-contact">
                    <RadioWithoutCheck
                      isChecked={input.checked}
                      onChange={() => input.onChange(user.id)}
                      size="medium"
                    />
                  </div>
                )}
              </Field>
            ) : (
              <Field
                name={`${fieldName}.roles`}
                value={role}
                type="checkbox"
                initialValue={userRoles}
              >
                {({ input }) => (
                  <CheckBox
                    isChecked={input.checked}
                    disabled={DISABLED_ROLES.includes(role)}
                    size="tiny"
                    label={null}
                    onChange={input.onChange}
                    hint={DISABLED_ROLES.includes(role) && t('user.roles.producer_hint')}
                    hintType="inverse"
                  />
                )}
              </Field>
            )}
          </TableData>
        ))}
      </TableRow>
    </>
  );
};

const RoleManagmentComponent = () => {
  const [isRecheckModalOpen, setIsRechekModalOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams({
    page: 1,
  });
  const navigate = useCustomNavigate();
  const { id } = useParams();
  const currentPage = searchParams.get('page');

  const userParams = {
    'organization_ids[]': [id],
    page: currentPage,
  };

  const { data: userCollection = {} } = useGetUsersQuery(userParams);

  const { data: organisation = {} } = useGetOrganizationQuery(id);
  const [updateRoles, { isLoading, isSuccess, isError, requestId }] =
    useUpdateOrganizationAssignmentsMutation();

  const users = userCollection?.users || [];
  const isProducerOrganisation = organisation.industry === PRODUCER;

  const handleSubmit = values => {
    const formData = new FormData();
    const ROLES = isProducerOrganisation ? [MANAGER, MEMBER, TEACHER] : [MEMBER];

    const filteredUserRoles = removeEmptyFromArray(values?.userRoles);

    filteredUserRoles?.forEach(userRole => {
      const roles = userRole.roles.filter(role => ROLES.includes(role));

      formData.append('assignments[][user_id]', userRole.userId);

      ROLES.forEach(role => {
        formData.append('assignments[][roles][][role]', role);
        if (!roles.includes(role)) {
          formData.append('assignments[][roles][][destroy]', true);
        }
      });

      if (values?.main_contact === userRole?.userId) {
        formData.append('assignments[][roles][][role]', MAIN_CONTACT);
      }
    });

    updateRoles({ organizationId: id, formData }).unwrap();
  };

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        mutators={{
          ...arrayMutators,
        }}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <div className="user__roles-form">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeader>{t('user.roles.table.name')}</TableHeader>
                    {getRolesColumns(isProducerOrganisation)?.map((headerName, idx) => (
                      <TableHeader align="center">
                        {t(`user.roles.table.${headerName}`)}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <FieldArray name="userRoles">
                    {() =>
                      users.map(user => (
                        <UserRolesTableRow
                          fieldName={`userRoles[${user.id}]`}
                          user={user}
                          mainContactId={organisation?.mainContactId}
                          isProducerOrganisation={isProducerOrganisation}
                        />
                      ))
                    }
                  </FieldArray>
                </TableBody>
              </Table>
            </div>
            <div className="flex-space-between padding-bottom-20">
              <div className="margin-top-10">
                <Button
                  submitType="submit"
                  type="success"
                  size="small"
                  label={t('shared.action.save')}
                />
                <Button
                  className="margin-left-10"
                  size="small"
                  label={t('shared.action.cancel')}
                  onClick={() => setIsRechekModalOpen(true)}
                />
              </div>
              <Pagination
                onPaginationClick={newPage => setSearchParams({ page: newPage })}
                totalPages={
                  isNonEmptyObject(userCollection) &&
                  parseInt(userCollection?.pagination?.totalPages, 10)
                }
                currentPage={parseInt(currentPage, 10)}
                firstText={t('pagination.first')}
                lastText={t('pagination.last')}
                nextText={t('pagination.next')}
                previousText={t('pagination.prev')}
              />
            </div>

            <SubmitModal
              isLoading={isLoading}
              isSuccess={isSuccess}
              isError={isError}
              requestId={requestId}
              successTitle={t('user.roles.modal.success_title')}
              successContent={t('user.roles.modal.success_content')}
              errorTitle={t('user.roles.modal.error_title')}
              errorContent={t('user.roles.modal.error_content')}
              showError={true}
              onSuccess={() => navigate.openOrganisationUserPage(id)}
            />
            <WarningModal
              onOutsideClick={() => setIsRechekModalOpen(false)}
              isVisible={isRecheckModalOpen}
              title={t('user.roles.modal.unsaved_warning_title')}
              content={t('user.roles.modal.unsaved_warning_content')}
              confirmActionText={t('shared.action.save')}
              cancelActionText={t('shared.action.cancel')}
              onCancel={() => {
                setIsRechekModalOpen(false);
                navigate.openOrganisationUserPage(id);
              }}
              onConfirm={() => {
                setIsRechekModalOpen(false);
                handleSubmit(values);
              }}
            />
          </form>
        )}
      />
    </>
  );
};

UserRolesTableRow.defaultProps = {
  fieldName: null,
  user: {},
  mainContactId: null,
  isProducerOrganisation: true,
};

UserRolesTableRow.propTypes = {
  fieldName: PropTypes.shape(),
  user: PropTypes.shape(),
  isProducerOrganisation: PropTypes.bool,
  mainContactId: PropTypes.node,
};

export default RoleManagmentComponent;
