import EmphasisTag from 'components/base-components/EmphasisTag';
import Popover from 'components/base-components/Popover';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const StackedTagItems = ({ content, displayItems }) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const filteredContent = content?.length > displayItems ? content.slice(0, displayItems) : content;
  const popoverItems = content?.length > displayItems && content.slice(displayItems);

  return (
    <div>
      {filteredContent.map(key => {
        return <EmphasisTag type={key.tag || 'normal'} text={key.text} size="medium" />;
      })}
      {popoverItems?.length > 0 && (
        <Popover
          isVisible={isPopoverVisible}
          size="medium"
          content={popoverItems?.map(item => (
            <div className="user__list--role">{item.text}</div>
          ))}
          direction="bottom-left"
          onOutsideClick={() => setIsPopoverVisible(false)}
        >
          <div className="user__list--popover-text" onClick={() => setIsPopoverVisible(true)}>
            {t('user_details.popver_text', {
              length: popoverItems?.length,
            })}
          </div>
        </Popover>
      )}
    </div>
  );
};

StackedTagItems.defaultProps = {
  content: {},
  displayItems: 2,
};

StackedTagItems.propTypes = {
  content: PropTypes.shape(),
  displayItems: PropTypes.number,
};

export default StackedTagItems;
