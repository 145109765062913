import ADDRESS_TYPE from 'utils/address-type';
import { preparePhoneNumbers } from './phone-number';

export const prepareNestedAttributes = ({ entityName, attributes, formData }) => {
  Object.keys(attributes).forEach(key => {
    if (attributes[key]) {
      formData.append(`${entityName}[][${key}]`, attributes[key]);
    }
  });
};

export const addressValues = (values, formData, phoneNumberByType) => {
  const addressesAttributes = ({ address, type, formData, main = false, destroyed = false }) => {
    const isOnlyCountrySelected = Object.keys(address).length === 1 && address.country;
    if (!address || isOnlyCountrySelected) {
      return {};
    }

    let attributes = {
      address_type: type,
      id: address.id,
      address_name: address.addressName || null,
      city: address.city || null,
      zip_code: address.zipCode || null,
      line1: address.line1 || null,
      line2: address.line2 || null,
      main: main,
      country_code: address?.country?.value,
      canton: address?.canton,
    };

    if (destroyed) {
      attributes = { ...attributes, destroy: true };
    }

    prepareNestedAttributes({ entityName: 'addresses', attributes, formData });
  };

  preparePhoneNumbers({ values, formData, phoneNumberByType });

  if (values.billingAddress || values.receiveInvoice) {
    addressesAttributes({
      address: values.billingAddress || { address_name: '' },
      type: ADDRESS_TYPE.invoice,
      formData,
      destroyed: !values.receiveInvoice,
    });
  }

  if (values.enableMailingAddress) {
    addressesAttributes({
      address: values.mailingAddress || { address_name: '' },
      type: ADDRESS_TYPE.mailing,
      formData,
      destroyed: !values.enableMailingAddress,
    });
  }

  if (values.mainAddress) {
    addressesAttributes({
      address: values.mainAddress || { address_type: ADDRESS_TYPE.general },
      type: ADDRESS_TYPE.general,
      formData,
      main: true,
    });
  }
};
