import { parseTemplate } from 'url-template';

function url(template, params) {
  const myParams = { ...params };
  if (myParams.format === undefined) {
    myParams.format = 'json';
  }

  return parseTemplate(template).expand(myParams);
}

const sharedUrl = (template, params) => {
  const prefix = '/public';
  const isPublic = window.location.pathname.includes(prefix);
  const filteredTemplate = isPublic ? prefix + template : template;

  return url(filteredTemplate, params);
};

const routes = {
  shared: {
    controlBodyDeclarations: {
      controlBodyDeclarationOptions: params =>
        sharedUrl('/organizations/control-bodies{?q,page}', params),
    },
    organizations: {
      legalFormOptions: params => sharedUrl('/organizations/legal-form-types', params),
      startingYears: params => sharedUrl('/organizations/starting-years', params),
      getValidation: params => sharedUrl('/organizations/validate', params),
      previousCultivationTypeOptions: params =>
        sharedUrl('/organizations/previous-cultivation-options', params),
      membershipOrganizations: params =>
        sharedUrl('/organizations/membership-organizations', params),
      collection: params => {
        const ORGANIZATION_URL =
          '/organizations{?q,status,page,per_page,parent_id,types[]*,except_organization_ids[]*,department[]*,star_count[]*,no_rating,minimum_users,organization_ids[]*,legitimization_date_range[start_date],legitimization_date_range[end_date]}';
        const additionalParams = (params?.range_of_users || [])
          .map(range => `&range_of_users[][min]=${range.min}&range_of_users[][max]=${range.max}`)
          .join('');
        return sharedUrl(ORGANIZATION_URL, params) + additionalParams;
      },
      entity: params => sharedUrl('/organizations/{organizationId}', params),
      assignments: params =>
        sharedUrl('/organizations/{organizationId}/assignments{?roles[]*}', params),
      registryLookup: params => sharedUrl('/organizations/registry-lookup/{uid}', params),
    },
    users: {
      entity: params => sharedUrl('/users/{id}', params),
    },
    productions: {
      collection: params => sharedUrl('/organizations/{organizationId}/productions/active', params),
      entity: params => sharedUrl('/productions/{productionId}/plant-cultivations', params),
      meatCollection: params => sharedUrl('/productions/{productionId}/meat-farms', params),
      eggProductions: params => sharedUrl('/productions/{productionId}/egg-farms', params),
      fishFarmProduction: params => sharedUrl('/productions/{productionId}/fish-farm', params),
      dairyFarmsCollection: params => sharedUrl('/productions/{productionId}/dairy-farms', params),
      gardeningItem: params => sharedUrl('/productions/{productionId}/gardening-item', params),
      beeHiveProductions: params => sharedUrl('/productions/{productionId}/bee-hive', params),
      otherFarmProduction: params => sharedUrl('/productions/{productionId}/other-farm', params),
      recreationalAndGreenAreas: params =>
        sharedUrl('/productions/{productionId}/recreational-and-green-areas', params),
      privateAndMunicipalAreas: params =>
        sharedUrl('/productions/{productionId}/private-and-municipal-areas', params),
    },
    tvdVignettes: {
      collection: params =>
        sharedUrl('/tvd-vignettes{?q,page,per_page,organization_ids[]*}', params),
    },
  },
  chartas: {
    overview: params => url('/organizations/{organizationId}/chartas/overview', params),
    active: params => url('/organizations/{organizationId}/chartas/active', params),
    wip: params => url('/organizations/{organizationId}/chartas/wip', params),
    declare: params => url('/organizations/{organizationId}/chartas/{chartaId}/declare', params),
    collection: params => url('/organizations/{organizationId}/chartas', params),
    items: {
      collection: params =>
        url('/organizations/{organizationId}/chartas/{chartaId}/items{?state}', params),
      accept: params =>
        url(
          '/organizations/{organizationId}/chartas/{chartaId}/items/{chartaItemId}/accept{?state}',
          params,
        ),
    },
  },
  companies: {
    entity: params => url('/companies/{companyIdentifier}', params),
    addresses: {
      eitity: params => url('/companies/{companyIdentifier}/addresses/{addressId}', params),
    },
    companyUnits: params =>
      url('/companies/{companyIdentifier}/company-units{?tvd_required}', params),
  },
  localUnit: {
    collection: params =>
      url('/organizations/{organisationId}/local-units{?q,page,per_page,offset}', params),
    entity: params => url('/local-units/{localUnitId}', params),
    delete: params => url('/local-units/{localUnitId}/archive', params),
  },
  productions: {
    milkProcessingSource: params => url('/productions/dairy-farms/milk-processing-sources', params),
    organicMilkOrganizations: params =>
      url('/productions/dairy-farms/organic-milk-producer-organizations', params),
    firstMilkBuyers: params => url('/productions/dairy-farms/first-milk-buyers', params),
    gardeningItemOptions: () => url('/productions/gardening-items'),
    otherFarmOptions: params => url('/productions/other-farms', params),
    arableCropsOptions: params => url('/productions/arable-crops', params),
    processingTypeOptions: params => url('/productions/processing-types', params),
    productTypeOptions: params => url('/productions/product-types', params),
    orchardTypeOptions: params => url('/productions/orchard-types', params),
    berryTypeOptions: params => url('/productions/berry-types', params),
    productionsByYear: params => url('/organizations/{organisationId}/productions/{year}', params),
    datalakeSeasonByYear: params => url('/companies/{companyId}/seasons/{year}', params),
  },
  controlBodySurveys: {
    collection: params =>
      url(
        '/control-body-surveys{?page,upcoming,column,direction,status,organization_ids[]*,sub_organization_ids[]*}',
        params,
      ),
    schedules: {
      entity: params => url('/schedules/{scheduleId}', params),
    },
  },
  availableLocales: () => url('/available-locales'),
  countries: {
    collection: params => url('/countries', params),
    cantons: params => url('countries/{code}/cantons', params),
  },
  contract: {
    upload: params => url('/organizations/{organisationId}/signed-contracts', params),
    entity: params => url('/organizations/{organisationId}/signed-contracts/last', params),
    collection: params =>
      url('/organizations/{organisationId}/signed-contracts{?statuses[]*}', params),
    update: params => url('/signed-contracts/{contractId}', params),
    cancel: params => url('/signed-contracts/{contractId}/cancel', params),
    archive: params => url('/signed-contracts/{contractId}/archive', params),
    terminate: params => url('/signed-contracts/{contractId}/terminate', params),
    terminationReasons: params => url('/signed-contracts/termination-reasons', params),
    send: params => url('/organizations/{organisationId}/signed-contracts/mark-as-sent', params),
    approve: params => url('/organizations/{organisationId}/signed-contracts/approve', params),
    delete: params => url('signed-contracts/{contractId}/attachment', params),
    reinstate: params => url('/organizations/{organisationId}/signed-contracts/reinstate', params),
  },
  controlBodyDeclarations: {
    overview: params =>
      url('/organizations/{organizationId}/control-body-declarations/overview', params),
    historyOfControlBodyDeclaration: params =>
      url('/organizations/{organizationId}/control-body-declarations/history{?page}', params),
    declareControlBodyDeclaration: params =>
      url('/organizations/{id}/control-body-declarations', params),
    raiseComplaintControlBodyDeclaration: params =>
      url('/organizations/{id}/control-body-declarations/complaints', params),
    verifyControlBodyDeclaration: params =>
      url('/organizations/{id}/control-body-declarations/verify', params),
    entityOfActiveControlBody: params =>
      url('/organizations/{organizationId}/control-body-declarations/active', params),
  },
  controlBodySchedule: {
    collection: params => url('/control-body-surveys/{surveyId}/schedules/active', params),
    entity: params => url('/control-body-surveys/{surveyId}/schedules/{scheduleId}/accept', params),
    declare: params =>
      url('/organizations/{organizationId}/control-body-surveys/set-schedule', params),
  },
  controlBodySurvey: {
    schedules: {
      collection: params => url('/control-body-surveys/{surveyId}/schedules/active', params),
      entity: params =>
        url('/control-body-surveys/{surveyId}/schedules/{scheduleId}/accept', params),
      declare: params =>
        url('/organizations/{organizationId}/control-body-surveys/set-schedule', params),
    },
    entity: params =>
      url('/organizations/{organizationId}/control-body-surveys/{surveyId}', params),
    submitNegativeReport: params => url('/control-body-surveys/{surveyId}/submit/negative', params),
    active: params => url('/organizations/{organizationId}/control-body-surveys/active', params),
    draftControl: params =>
      url('/organizations/{organizationId}/control-body-surveys/draft', params),
    logoCollection: params => url('/control-body-surveys/logos', params),
    logoAttachmentsCollection: params =>
      url('/control-body-surveys/{surveyId}/logo-attachments{?type,ids[]*}', params),
    restrictedIngredientsOptions: params =>
      url('/control-body-surveys/restricted-ingredients', params),
    restrictedIngredientsCollection: params =>
      url('/control-body-surveys/{surveyId}/restricted-ingredients{?type,ids[]*}', params),
    ingredientReservesCollection: params =>
      url('/control-body-surveys/{surveyId}/ingredient-reserves{?type,ids[]*}', params),
    accountingCollection: params => url('/control-body-surveys/accounting-processes', params),
    activeAccountingCollection: params =>
      url('/control-body-surveys/{surveyId}/accounting-processes', params),
    audioResponseCollection: params =>
      url('/control-body-surveys/{surveyId}/audio-responses{?type,ids[]*}', params),
    statusCollection: params =>
      url('/control-body-surveys/{surveyId}/declaration-statuses', params),
    goodsDeclarationEvaluationCollecton: params =>
      url('/control-body-surveys/{surveyId}/goods-declaration-evaluations', params),
    goodsDeclarationCustomInputEntity: params =>
      url('/random-goods-declarations/{id}/verify-custom-input', params),
    customVatPercentageReasonOptions: params =>
      url('/random-goods-declarations/vat-percentage-reasons'),
    finish: params => url('/control-body-surveys/{surveyId}/submit/draft', params),
    draft: params => url('/control-body-surveys/{surveyId}/save', params),
    certifierFinish: params => url('/control-body-surveys/{surveyId}/check-result', params),
    last: params => url('/organizations/{organizationId}/control-body-surveys/last', params),
    surveyEntity: params =>
      url('/organizations/{organizationId}/control-body-surveys/active', params),
    deleteImage: params => url('/attachments/{attachmentId}', params),
    deleteAudio: params => url('/audio-responses/{audioResponseId}/archive', params),
    certificates: {
      request: params => url('/control-body-surveys/{surveyId}/certificates/request', params),
      upload: params => url('/control-body-surveys/{surveyId}/certificates/upload', params),
      delete: params => url('/control-body-surveys/{surveyId}/certificates/delete', params),
    },
    report: params => url('/control-body-surveys/{surveyId}/report', params),
  },
  offlineSession: {
    collection: params =>
      url('/control-body-surveys/{surveyId}/offline-sessions{?session_for}', params),
    verify: params => url('/offline-sessions/{sessionId}/verify', params),
  },
  ingredientReserves: {
    active: params =>
      url('/control-body-surveys/{surveyId}/ingredient-reserves-additional-tasks/active', params),
    declare: params =>
      url('/control-body-surveys/{surveyId}/ingredient-reserves-additional-tasks', params),
    approve: params =>
      url('/control-body-surveys/{surveyId}/ingredient-reserves-additional-tasks/approve', params),
    getReservedIngredientPhotos: params =>
      url('/control-body-surveys/{surveyId}/reserved-ingredient-additional-photos', params),
  },
  restrictedIngredients: {
    active: params =>
      url(
        '/control-body-surveys/{surveyId}/restricted-ingredients-additional-tasks/active',
        params,
      ),
    declare: params =>
      url('/control-body-surveys/{surveyId}/restricted-ingredients-additional-tasks', params),
    approve: params =>
      url(
        '/control-body-surveys/{surveyId}/restricted-ingredients-additional-tasks/approve',
        params,
      ),
    getRestrictedIngredientAdditionalPhotos: params =>
      url('/control-body-surveys/{surveyId}/restricted-ingredient-additional-photos', params),
  },
  accountingProcesses: {
    entity: params => url('/accounting-processes/{id}', params),
    active: params =>
      url('/control-body-surveys/{surveyId}/accounting-process-additional-tasks/active', params),
    declare: params =>
      url('/control-body-surveys/{surveyId}/accounting-process-additional-tasks', params),
    approve: params =>
      url('/control-body-surveys/{surveyId}/accounting-process-additional-tasks/approve', params),
  },
  logoAttachments: {
    active: params =>
      url('/control-body-surveys/{surveyId}/logo-attachment-additional-tasks/active', params),
    declare: params =>
      url('/control-body-surveys/{surveyId}/logo-attachment-additional-tasks', params),
    approve: params =>
      url('/control-body-surveys/{surveyId}/logo-attachment-additional-tasks/approve', params),
    getLogoAttachmentPhotos: params =>
      url('/control-body-surveys/{surveyId}/logo-attachment-photos', params),
  },
  verifyPin: params => url('/me/verify-pin', params),
  verifyOtp: params => url('/me/verify-otp', params),
  twoFactorAuthentication: {
    provisioningUri: () => url('/me/two-factor-authentication/provisioning-uri'),
    disable: () => url('/me/two-factor-authentication/disable'),
    enable: params => url('/me/two-factor-authentication/enable', params),
    verify: params => url('/me/two-factor-authentication/verify', params),
  },
  password: {
    setPassword: params => url('/me/password', params),
    resetPassword: params => url('/me/password/reset', params),
    resetRequest: params => url('/me/password/request-reset{?email}', params),
  },
  publicRegistration: {
    requestToken: () => url('/public/registrations/request-token'),
  },
  public: {
    verify: params => url('/public/registrations/verify-token', params),
  },
  organizations: {
    declarationStatus: params => url('/organizations/{organizationId}/declarations-status', params),
    contractUsers: params => url('/organizations/{id}/contract-users', params),
    health: params => url('/organizations/{organizationId}/health', params),
    history: params => url('/organizations/{id}/histories?page={page}', params),
    specificOrganizationOverview: params => url('/organizations/{organizationId}/overview', params),
    industries: params => url('/organizations/industries', params),
    deactivate: params => url('/organizations/{id}/active', params),
    abort: params => url('/organizations/{id}/abort', params),
    registered: params => url('/organizations/registered/{uid}{?type}', params),
    overview: () => url('/organizations/overview'),
    entityOfOverview: params => url('/organizations/{id}/overview', params),
    titles: () => url('/organizations/titles'),
    address: params => url('/organizations/{organisationId}/address', params),
    sync: params => url('/organizations/{organizationId}/sync', params),
    syncAll: () => url('/organizations/sync-all'),
    superOfficeDetails: params =>
      url('/organizations/{organisationId}/super-office-details', params),
  },
  currentUser: {
    entity: () => url('/me'),
    session: () => url('/me/session'),
  },
  users: {
    collection: params => url('/users{?q,status,page,organization_ids[]*,types[]*}', params),
    invite: () => url('/users/invites'),
    reset: () => url('/me/password'),
    deactivate: params => url('/users/{userId}/deactivate', params),
    reactivate: params => url('/users/{userId}/reactivate', params),
    resendUserInvitation: params => url('/users/{userId}/resend-invitation', params),
    spokenLanguageList: () => url('/users/spoken_language_options'),
    interests: params => url('/users/{userId}/interests', params),
  },
  roles: {
    collection: () => url('/users/roles'),
    collectionOfOrganisation: () => url('/organizations/roles'),
  },
  educations: {
    collectionOfUser: params => url('/users/{userId}/educations', params),
    degrees: () => url('/educations/degrees'),
    institutes: () => url('/educations/institutes'),
    entity: params => url('/educations/{educationId}', params),
    overview: params => url('/organizations/{organizationId}/educations/overview', params),
    declarations: {
      entity: params => url('/education-declarations/{educationDeclarationId}', params),
    },
  },
  invoice: {
    list: params => {
      const INVOICES_URL =
        '/invoices{?q,page,per_page,statuses[]*,job_ids[]*,types[]*,creation_date,industries[]*,organization_ids[]*,invoice_date[start_date],invoice_date[end_date],due_date[start_date],due_date[end_date]}';

      return url(INVOICES_URL, params);
    },
    collectionOfItems: params => url('/invoices/{invoiceId}/items', params),
    collectionOfTransactions: params => url('/invoices/{invoiceId}/transactions', params),
    entity: params => url('/invoices/{invoiceId}.{format}', params),
    manual: params => url('/organizations/{organisationId}/invoices', params),
    markAsOpen: params => url('/invoices/mark-as-open', params),
    markAsCancel: params => url('/invoices/cancel', params),
    correct: params => url('/invoices/{id}/adjust', params),
    reopen: params => url('/invoices/{id}/reopen', params),
    invoiceGroups: params => url('/invoices/groups{?statuses[]*,page}', params),
    reject: params => url('/invoice-jobs/{invoiceId}/reject-invoices', params),
    release: params => url('/invoice-jobs/{entityId}/release', params),
    releaseSingleInvoice: params => url('/invoices/{entityId}/release', params),
    invoiceJob: params => url('/invoice-jobs{?page}', params),
    entityOfInvoiceJob: params => url('/invoice-jobs/{invoiceJobId}', params),
    issueCreditNote: params => url('/invoices/{id}/credit-note', params),
  },
  tasks: {
    collection: params =>
      url(
        '/tasks{?q,status,assigned_to[]*,author_ids[]*,page,per_page,due_date,referenceable_id,types[]*}',
        params,
      ),
    entity: () => url('/tasks'),
    entityOfTask: params => url('/tasks/{id}', params),
    collectionOfComments: params => url('/tasks/{id}/comments', params),
    enityOfComment: params => url('/comments/{id}', params),
    markAsDone: params => url('/tasks/{id}/mark-as-done', params),
  },
  tvdVignettes: {
    upload: () => url('/tvd-vignettes/upload'),
    collection: params => url('/tvd-vignettes{?q,page,per_page,organization_ids[]*}', params),
    order: params => url('/tvd-vignettes/{localUnitId}/send', params),
  },
  messages: {
    collection: params =>
      url('/messages{?q,type,recipient,sender,sender_ids[]*,page,per_page}', params),
    entity: () => url('/messages'),
    entityOfMessage: params => url('/messages/{id}', params),
    entityOfConfirmMessage: params => url('/messages/{id}/confirm', params),
  },
  recognition: {
    countByDate: params => url('/recognitions/count-by-date{?statuses[]*}', params),
    collection: params =>
      url('/recognitions{?date,statuses[]*,organization_ids[]*,page,per_page,offset}', params),
    update: params => url('/recognitions/mark-as-sent', params),
    entity: params => url('/recognitions/{recognitionId}.{format}{?with_logo}', params),
  },
  email: {
    collectionOfUser: params => url('/users/{userId}/email_addresses', params),
    entity: params => url('/users/{userId}/email_addresses/{id}', params),
  },
  phone: {
    collectionOfUser: params => url('/users/{userId}/phone_numbers', params),
    entity: params => url('/users/{userId}/phone_numbers/{id}', params),
  },
  assignments: {
    collectionOfUser: params => url('/users/{id}/assignments{?page}', params),
    entity: params => url('/users/{user_id}/assignments/{id}', params),
  },
  productDeclarations: {
    collection: params =>
      url('/organizations/{organizationId}/product-declarations{?type}', params),
    overview: params =>
      url('/organizations/{organizationId}/product-declarations/overview', params),
    submit: params => url('/organizations/{organizationId}/product-declarations/submit', params),
    approve: params => url('/organizations/{organizationId}/product-declarations/approve', params),
  },
  products: {
    categories: () => url('/products/categories'),
    collection: params => url('/organizations/{organizationId}/products{?types[]*,ids[]*}', params),
    complaints: params =>
      url('/organizations/{organizationId}/products/{productId}/complaints', params),
    entity: params => url('/organizations/{organizationId}/products/{productId}', params),
  },
  suppliers: {
    certificates: {
      collectionOfOrganization: params =>
        url('/organizations/{organizationId}/supplier-certificates{?q,status}', params),
      overview: params =>
        url('/organizations/{organizationId}/supplier-certificates/overview', params),
      entity: params => url('/supplier-certificates/{certificateId}', params),
      complaints: params =>
        url(
          '/organizations/{organizationId}/certificate-declarations/{certificateDeclarationId}/complaints',
          params,
        ),
      singleComplaint: params => url('/supplier-certificates/{certificateId}/complaints', params),
      collectionOfHistory: params =>
        url(
          '/organizations/{organizationId}/certificate-declarations/history{?organization_id,status,page}',
          params,
        ),
    },
    declarations: {
      collectionOfOrganization: params =>
        url(
          '/organizations/{organizationId}/certificate-declarations{?organization_id,status,page,valid_from,valid_until}',
          params,
        ),
      collectionOfDeclaration: params =>
        url(
          '/organizations/{organizationId}/certificate-declarations/{certificateDeclarationId}/supplier-certificates{?page}',
          params,
        ),
      declare: params =>
        url('/organizations/{organizationId}/certificate-declarations/declare', params),
      approve: params =>
        url('/organizations/{organizationId}/certificate-declarations/approve', params),
    },
  },
  goods: {
    collectionOfGoods: params =>
      url('/organizations/{id}/goods-declarations{?page,status,ids[]*}', params),
    entity: params => url('/goods-declarations/{id}', params),
    activeGoods: params => url('/organizations/{id}/goods-declarations/active', params),
    activeGoodsOverview: params =>
      url('/organizations/{organizationId}/goods-declarations/overview', params),
    updateGoods: params => url('/goods-declarations/{goods_id}', params),
    declareGoods: params =>
      url('/organizations/{organizationId}/goods-declarations/declare', params),
    approveGoods: params =>
      url('/organizations/{organizationId}/goods-declarations/approve', params),
    complaintsGoods: params => url('/goods-declarations/{goodsDeclarationId}/complaints', params),
  },
  educationAssignee: {
    entity: params => url('/organizations/{organizationId}/education-assignee', params),
    educations: {
      collection: params => url('/education-assignees/{educationAssigneeId}/educations', params),
      declarations: {
        collection: params =>
          url('/education-assignees/{educationAssigneeId}/education-declarations', params),
        submit: params =>
          url('/education-assignees/{educationAssigneeId}/education-declarations/submit', params),
        approve: params =>
          url('/education-assignees/{educationAssigneeId}/education-declarations/approve', params),
        complaints: params =>
          url('/education-declarations/{educationDeclarationId}/complaints', params),
      },
    },
  },
};

export default routes;
