import React from 'react';
import EmphasisTag from 'components/base-components/EmphasisTag';
import { useGetUserQuery } from 'api/users';
import { useGetOrganizationQuery, useGetOrganizationAssignmentsQuery } from 'api/organizations';
import { OPERATION_MANAGER, MAIN_CONTACT } from 'utils/users-role';
import { Link, useParams } from 'react-router-dom';
import { getCertificationStatus } from 'utils/get-certification-status';
import { t } from 'i18next';
import Card from 'components/base-components/Card';
import UserInfo from 'components/users/user-info.component';
import useNavigationLinks from 'hooks/useNavigationLinks';
import PropTypes from 'prop-types';

const OverviewComponent = () => {
  const { id } = useParams();
  const navigationLinks = useNavigationLinks();
  const { data: organisation } = useGetOrganizationQuery(id);

  const { data: assignments = [] } = useGetOrganizationAssignmentsQuery({
    organizationId: id,
    'roles[]': [OPERATION_MANAGER, MAIN_CONTACT],
  });

  const operationManagers = assignments.filter(assignment => assignment.role === OPERATION_MANAGER);

  const mainContactAssignment = assignments.find(assignment => assignment.role === MAIN_CONTACT);

  const { data: mainContact } = useGetUserQuery(
    { id: mainContactAssignment?.userId },
    { skip: !mainContactAssignment?.userId },
  );
  const { data: contolBodyOrganisation } = useGetOrganizationQuery(
    organisation?.controlBodyOrganizationId,
    {
      skip: !organisation?.controlBodyOrganizationId,
    },
  );

  const producerStatusTagColor = {
    u1: 'accent',
    u2: 'accent',
    u3: 'accent',
    vk: 'success',
  };

  const organisationStatusTagColor = {
    active: 'success',
    inactive: 'danger',
    aborted: 'danger',
    draft: 'accent',
    registered: 'accent',
    interested: 'warning',
    undefined: 'warning',
  };

  return (
    <Card>
      <div className="font-bold font-16 margin-bottom-20">
        {t('organisation.details.tabs.overview')}
      </div>
      <div className="flex-space-between margin-bottom-15">
        <div>
          <div className="font-bold">{t('producer.dashboard.bio_suisse_status')}</div>
          <div className="producer__emphasis-tag--bio-suisse margin-top-10">
            <EmphasisTag
              type={producerStatusTagColor[organisation?.bioSuisseStatus]}
              text={organisation?.bioSuisseStatus?.toUpperCase()}
              size="medium"
            />
          </div>
        </div>
        <div className="organisation-details__head--title__vertical-line" />
        <div>
          <div className="font-bold">{t('producer.dashboard.certification_status')}</div>
          <div className="producer__emphasis-tag--certification margin-top-10">
            {getCertificationStatus(organisation?.certificationStatus) ? (
              <EmphasisTag
                type={producerStatusTagColor[organisation?.certificationStatus]}
                text={organisation?.certificationStatus?.toUpperCase()}
                size="medium"
              />
            ) : (
              t('common.not_applicable')
            )}
          </div>
        </div>
      </div>
      <div className="flex-space-between">
        <div className="font-bold">{t('producer.dashboard.organisation_status')}</div>
        <div className="producer__organisation-status">
          <EmphasisTag
            type={organisationStatusTagColor[organisation?.status]}
            text={organisation?.status}
            size="medium"
          />
        </div>
      </div>
      <div className="flex-space-between margin-top-15">
        <div className="font-bold">{t('producer.dashboard.contract_status')}</div>
        <div>
          {organisation?.contractStatus ? (
            t(`contract.attributes.${organisation?.contractStatus}`)
          ) : (
            <span className="text-disabled">{t('common.not_applicable')}</span>
          )}
        </div>
      </div>
      <hr className="organisation-checklist__checkbox-horizontal-line margin-top-20" />
      <div>
        <div className="producer__maf--wrapper margin-top-20">
          <div>
            <div className="font-bold">{t('producer.dashboard.debit_number')}</div>
            <div className="margin-top-10">
              {organisation?.debitNumber || t('common.not_applicable')}
            </div>
          </div>
          <div className="producer__maf--content">
            <div className="font-bold">{t('producer.dashboard.maf')}</div>
            <div className="margin-top-10">{t('producer.dashboard.disabled')}</div>
          </div>
        </div>
        <div className="flex-space-between margin-top-20">
          <div>
            <div className="producer__control-body font-bold">
              {t('organisation_overview.control_body')}
            </div>
            <div className="margin-top-10">
              {contolBodyOrganisation?.name || (
                <span className="text-disabled">{t('common.not_applicable')}</span>
              )}
            </div>
          </div>
        </div>
        <hr className="organisation-checklist__checkbox-horizontal-line margin-top-20" />
        <div className="margin-top-20 font-bold">{t('legal_form.contract_person.title')}</div>
        <div className="margin-top-10 producer__operation-managers">
          {mainContact ? (
            <UserInfo userData={mainContact} size="tiny" />
          ) : (
            <span className="text-disabled">{t('common.not_applicable')}</span>
          )}
        </div>
        <div className="font-bold margin-top-20">{t('producer.dashboard.operation_manager')}</div>
        {operationManagers.map(manager => (
          <div className="margin-top-10 producer__operation-managers">
            <UserInfo userData={manager.userId} size="tiny" />
          </div>
        ))}
      </div>
      <div className="margin-top-20 producer__links">
        <Link to={navigationLinks.organisationUserPage(id)}>
          {t('producer.dashboard.view_users')}
        </Link>
      </div>
    </Card>
  );
};

OverviewComponent.defaultProps = {
  operationManagers: [],
};

OverviewComponent.propTypes = {
  operationManagers: PropTypes.arrayOf(PropTypes.shape()),
};

export default OverviewComponent;
