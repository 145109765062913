import { useGetUserQuery } from 'api/users';
import classNames from 'classnames';
import Spinner from 'components/base-components/Spinner';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import useNavigationLinks from '../../hooks/useNavigationLinks';

const UserName = ({ userID, className, enableLink, userData }) => {
  const {
    data: fetchedUser = {},
    error,
    isFetching,
  } = useGetUserQuery({ id: userID }, { skip: userData });
  const navigationLinks = useNavigationLinks();
  if (isFetching) return <Spinner bgColor="none" color="success" size="tiny" />;
  if (error) return null;

  const user = userData || fetchedUser;

  return enableLink ? (
    <Link to={navigationLinks.userDetailsPage(user.id)}>
      <span className={classNames('user-name', className)}>{user.name}</span>
    </Link>
  ) : (
    <span className={classNames('user-name', className)}>{user.name}</span>
  );
};

UserName.defaultProps = {
  className: null,
  enableLink: true,
  userData: null,
};

UserName.propTypes = {
  userID: PropTypes.number.isRequired,
  className: PropTypes.string,
  enableLink: PropTypes.bool,
  userData: PropTypes.shape({
    profilePictureUrl: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    name: PropTypes.string,
  }),
};

export default UserName;
