import React from 'react';
import WelcomePageComponent from 'components/welcomepage/welcome-page.component';
import EmailVerificationComponent from 'components/public-registrations/email-verification.component';

export default function EmailVerification() {
  return (
    <WelcomePageComponent onlyChildren={true}>
      <EmailVerificationComponent />
    </WelcomePageComponent>
  );
}
