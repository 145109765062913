import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { moveToPreviousPage } from 'utils/navigation-utils';
import Button from 'components/base-components/Button';
import Modal from 'components/base-components/Modal';
import Icon from 'components/base-components/Icon';
import { useNavigate } from 'react-router-dom';
import { CONTROL_BODY } from 'utils/organisation-type';
import { GASTRONOMY, PRODUCER } from 'utils/organisation-industries';
import { useCreateOrganizationMutation } from 'api/organizations';
import { Header } from 'components/base-components/Typography';
import Card from 'components/base-components/Card';
import LoginLogo from 'assets/bio_logo.png';
import IsPublicRoute from 'utils/is-public-route';
import OrganizationForm from '../form/index.component';
import '../organisations.styles.scss';

const OrganizationTypeIcon = ({ type, setOrganizationType, selected }) => {
  const { t } = useTranslation();

  const ICON_MAPPER = {
    gastronomy: 'restaurant',
    producer: 'producer',
    control_body: 'controlBody',
  };

  const translatedOrganizationType =
    type === CONTROL_BODY
      ? t('bio_cuisine_status.steps.control_body')
      : t(`organisation.industry.${type}`);

  return (
    <div
      className={classNames('organisation-create__modal-content-type', {
        'organisation-create__modal-content-type--selected': selected,
      })}
      data-testid="organisation-type"
      onClick={() => setOrganizationType(type)}
    >
      <Icon fill="none" name={ICON_MAPPER[type]} size="xxxl" />
      <span>{translatedOrganizationType}</span>
    </div>
  );
};

OrganizationTypeIcon.propTypes = {
  type: PropTypes.string.isRequired,
  setOrganizationType: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};

const CreateForm = ({ department }) => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [organizationType, setOrganizationType] = useState(null);
  const [createOrganisation, status] = useCreateOrganizationMutation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isPublic = IsPublicRoute();
  const industry = organizationType !== CONTROL_BODY ? organizationType : null;

  useEffect(() => {
    if (department) {
      setOrganizationType(department);
    }
  }, []);

  const onCreate = formData => {
    if (industry) {
      formData.append('industry', industry);
    }
    return createOrganisation({ payload: formData }).unwrap();
  };

  return (
    <div className={classNames('organisation-create', { 'organisation-create--public': isPublic })}>
      {isPublic && (
        <Card className="organisation__public-header">
          <div className="flex-with-center-align">
            <img src={LoginLogo} alt="BioSuisseLogo" height={50} width={50} />
            <Header className="margin-left-20" level={6} weight="boldest">
              {t('public_organisation.new_organisation')}
            </Header>
          </div>
        </Card>
      )}
      {!department && (
        <Modal isVisible={isModalOpen} className="organisation-create__modal">
          <Modal.Title className="organisation-create__modal-title">
            <span>{t('organisation.form.modal.organisation_type.title.select')}</span>
            <span>{t('organisation.form.modal.organisation_type.title.addtional_text')}</span>
          </Modal.Title>
          <Modal.Content className="organisation-create__modal-content">
            <OrganizationTypeIcon
              type={GASTRONOMY}
              setOrganizationType={setOrganizationType}
              selected={organizationType === GASTRONOMY}
            />
            <OrganizationTypeIcon
              type={PRODUCER}
              setOrganizationType={setOrganizationType}
              selected={organizationType === PRODUCER}
            />
            <OrganizationTypeIcon
              type={CONTROL_BODY}
              setOrganizationType={setOrganizationType}
              selected={organizationType === CONTROL_BODY}
            />
          </Modal.Content>
          <Modal.Footer>
            <Button
              label={t('organisation.form.action.create_form')}
              type="success"
              onClick={() => setIsModalOpen(false)}
              disabled={!organizationType}
            />
            <Button
              label={t('shared.action.cancel')}
              onClick={() => navigate(moveToPreviousPage)}
            />
          </Modal.Footer>
        </Modal>
      )}

      {organizationType && (
        <OrganizationForm
          onSave={onCreate}
          department={organizationType}
          status={status}
          industry={industry}
          isCreateForm={true}
        />
      )}
    </div>
  );
};
CreateForm.defaultProps = {
  department: null,
};

CreateForm.propTypes = {
  department: PropTypes.string,
};

export default CreateForm;
