import { useGetProductionQuery, useGetProductionsQuery } from 'api/producers/productions';
import { Tabs } from 'components/base-components/RTabs';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useFormState } from 'react-final-form';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import PLANT_CULTIVATION from 'utils/plant-cultivation';
import { useForm } from 'react-final-form';
import handleMoveToProductionStep from '../helpers/handleMoveProductionTab';
import Egg from './Egg/index.component';
import BeeHiveProduction from './bee-hive/bee-hive-production.component';
import FishProduction from './fish-farming/fish-production.component';
import GardeningComponent from './gardening/gardening.component';
import GreenAreaProduction from './green-areas/green-area-production.component';
import MeatTypesComponent from './meat/meat-types/meat-types.component';
import MilkTypesComponent from './milk/milk-types.component';
import OtherFarmProduction from './other-farms/other-farm-production.component';
import './plant-cultivate/plant-cultivation.styles.scss';
import PlantTypesComponent from './plant-cultivate/plant-types/plant-types.component';
import PrivateParkProduction from './private-park/private-park-production.component';
import ProducerTypeWithIcon from './shared/production-type-with-icon.component';
import EmptyProducerContent from './shared/empty-producer-content.component';

const ProductionCultivation = ({
  validationError,
  triggerStepperProductionTabs,
  setTriggerStepperProductionTabs,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tabsRef = useRef();
  const { id } = useParams();
  const form = useForm();

  const { values } = useFormState();

  const TAB_MAPPER = {
    plant_cultivation: 0,
    meat_production: 1,
    egg: 2,
    milk: 3,
    fish: 4,
    bee: 5,
    animal: 6,
    garden: 7,
    greenArea: 8,
    privatePark: 9,
  };

  const { data: farmlandData } = useGetProductionsQuery(
    {
      organizationId: id,
    },
    { skip: !id },
  );

  const { data: plantCultivationData } = useGetProductionQuery(
    {
      productionId: farmlandData?.id,
    },
    { skip: !farmlandData },
  );

  const currentTab = searchParams.get('tab');

  useEffect(() => {
    const selectedProductionDatas = values?.production?.filter(data => !!data);
    if (selectedProductionDatas) {
      setSearchParams({ tab: selectedProductionDatas[0] });
    }
  }, [values?.production?.length]);

  useEffect(() => {
    if (triggerStepperProductionTabs) {
      if (
        (Object.keys(validationError)?.length === 0 && triggerStepperProductionTabs === 'next') ||
        triggerStepperProductionTabs === 'previous'
      ) {
        const tabNavigationActions = {
          next: () => tabsRef.current?.handleRightNavClick(),
          previous: () => tabsRef.current?.handleLeftNavClick(),
        };

        tabNavigationActions[triggerStepperProductionTabs]?.();

        const nextTabValue =
          triggerStepperProductionTabs === 'next'
            ? handleMoveToProductionStep(values, currentTab, 'next')
            : handleMoveToProductionStep(values, currentTab, 'previous');
        setSearchParams({ tab: nextTabValue });
        if (
          triggerStepperProductionTabs === 'previous' &&
          currentTab === values?.production?.filter(value => value !== null)[0]
        ) {
          setTriggerStepperProductionTabs('previous');
        } else {
          setTriggerStepperProductionTabs(false);
        }
      }
    }
  }, [triggerStepperProductionTabs, currentTab, values, validationError]);

  const isTabHidden = tabName => !values?.production?.includes(tabName);

  const onChangeSubTypeSelection = (main, subType, checked) => {
    let removedSubTypes = form.getState().values.removedSubTypes || {};
    let associatedSubTypes = removedSubTypes[main] || [];
    if (checked) {
      associatedSubTypes = associatedSubTypes.filter(item => item !== subType);
    } else {
      associatedSubTypes.push(subType);
    }

    if (associatedSubTypes.length > 0) {
      removedSubTypes = { ...removedSubTypes, [main]: associatedSubTypes };
    } else {
      delete removedSubTypes[main];
    }

    form.change('removedSubTypes', removedSubTypes);
  };

  return (
    <div>
      <div className="col-bleed">
        {currentTab === 'undefined' ? (
          <EmptyProducerContent />
        ) : (
          <div>
            <Tabs
              ref={tabsRef}
              slideDistanceController={6}
              className="plant-cultivation__tabs"
              controlled={true}
              selectedTabKey={TAB_MAPPER[currentTab]}
              isConditional={!values?.production?.some(data => !!data)}
              items={[
                {
                  title: (
                    <ProducerTypeWithIcon
                      name="plantTab"
                      i18nTranslation={t('plant_cultivation.tab.plant_cultivation')}
                    />
                  ),
                  key: TAB_MAPPER.plant,
                  hidden: isTabHidden(PLANT_CULTIVATION.plant_cultivation),
                  getContent: () => (
                    <PlantTypesComponent
                      plantCultivationData={plantCultivationData}
                      values={values}
                      onChangeSubTypeSelection={onChangeSubTypeSelection}
                      validationError={validationError}
                    />
                  ),
                },
                {
                  title: (
                    <ProducerTypeWithIcon
                      name="meat_production"
                      i18nTranslation={t('plant_cultivation.tab.meat_production')}
                    />
                  ),
                  key: TAB_MAPPER.meat,
                  hidden: isTabHidden(PLANT_CULTIVATION.meat_production),
                  getContent: () => (
                    <MeatTypesComponent
                      onChangeSubTypeSelection={onChangeSubTypeSelection}
                      validationError={validationError}
                    />
                  ),
                },
                {
                  title: (
                    <ProducerTypeWithIcon
                      name="egg"
                      i18nTranslation={t('plant_cultivation.tab.egg')}
                    />
                  ),
                  key: TAB_MAPPER.egg,
                  hidden: isTabHidden(PLANT_CULTIVATION.egg),
                  getContent: () => (
                    <Egg
                      id={farmlandData?.id}
                      onChangeSubTypeSelection={onChangeSubTypeSelection}
                      validationError={validationError}
                    />
                  ),
                },
                {
                  title: (
                    <ProducerTypeWithIcon
                      name="milk"
                      i18nTranslation={t('plant_cultivation.tab.milk')}
                    />
                  ),
                  key: TAB_MAPPER.milk,
                  hidden: isTabHidden(PLANT_CULTIVATION.milk),
                  getContent: () => (
                    <MilkTypesComponent
                      onChangeSubTypeSelection={onChangeSubTypeSelection}
                      validationError={validationError}
                    />
                  ),
                },
                {
                  title: (
                    <ProducerTypeWithIcon
                      name="fish"
                      i18nTranslation={t('plant_cultivation.tab.fish')}
                    />
                  ),
                  key: TAB_MAPPER.fish,
                  hidden: isTabHidden(PLANT_CULTIVATION.fish),
                  getContent: () => (
                    <FishProduction
                      farmlandId={farmlandData?.id}
                      validationError={validationError}
                    />
                  ),
                },
                {
                  title: (
                    <ProducerTypeWithIcon
                      name="bee"
                      i18nTranslation={t('plant_cultivation.bee_hives.bee')}
                    />
                  ),
                  key: TAB_MAPPER.bee,
                  hidden: isTabHidden(PLANT_CULTIVATION.bee),
                  getContent: () => (
                    <BeeHiveProduction
                      farmlandId={farmlandData?.id}
                      validationError={validationError}
                    />
                  ),
                },
                {
                  title: (
                    <ProducerTypeWithIcon
                      name={'animal'}
                      i18nTranslation={t('plant_cultivation.tab.animal')}
                    />
                  ),
                  key: TAB_MAPPER.animal,
                  hidden: isTabHidden(PLANT_CULTIVATION.animal),
                  getContent: () => (
                    <OtherFarmProduction
                      productionId={farmlandData?.id}
                      validationError={validationError}
                    />
                  ),
                },
                {
                  title: (
                    <ProducerTypeWithIcon
                      name="garden"
                      i18nTranslation={t('plant_cultivation.tab.garden')}
                    />
                  ),
                  key: TAB_MAPPER.garden,
                  hidden: isTabHidden(PLANT_CULTIVATION.garden),
                  getContent: () => (
                    <GardeningComponent
                      productionId={farmlandData?.id}
                      validationError={validationError}
                    />
                  ),
                },
                {
                  title: (
                    <ProducerTypeWithIcon
                      name="green_area"
                      i18nTranslation={t('plant_cultivation.tab.green_area')}
                    />
                  ),
                  key: TAB_MAPPER.greenArea,
                  hidden: isTabHidden(PLANT_CULTIVATION.greenArea),
                  getContent: () => (
                    <GreenAreaProduction
                      values={values}
                      productionId={farmlandData?.id}
                      validationError={validationError}
                    />
                  ),
                },
                {
                  title: (
                    <ProducerTypeWithIcon
                      name="private_park"
                      i18nTranslation={t('plant_cultivation.tab.private_park')}
                    />
                  ),
                  key: TAB_MAPPER.privatePark,
                  hidden: isTabHidden(PLANT_CULTIVATION.privatePark),
                  getContent: () => (
                    <PrivateParkProduction
                      values={values}
                      productionId={farmlandData?.id}
                      validationError={validationError}
                    />
                  ),
                },
              ]}
            />
            <div className="error-text margin-top-10">{validationError?.empty_production}</div>
          </div>
        )}
      </div>
    </div>
  );
};

ProductionCultivation.defaultProps = {
  validationError: {},
  triggerStepperProductionTabs: false,
  setTriggerStepperProductionTabs: () => {},
};

ProductionCultivation.propTypes = {
  validationError: PropTypes.shape(),
  triggerStepperProductionTabs: PropTypes.bool,
  setTriggerStepperProductionTabs: PropTypes.func,
};

export default ProductionCultivation;
