import React from 'react';
import Card from 'components/base-components/Card';
import Input from 'components/base-components/Input';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import Button from 'components/base-components/Button';
import Icon from 'components/base-components/Icon';
import ReCAPTCHA from 'react-google-recaptcha';
import { useRequestTokenMutation } from 'api/public-registration';
import { Trans } from 'react-i18next';
import './public-registrations.scss';

const EmailVerificationComponent = () => {
  const [requestToken, { isSuccess: isEmailSent }] = useRequestTokenMutation();
  const { t } = useTranslation();

  const onSubmit = values =>
    requestToken(values)
      .unwrap()
      .catch(({ data: { errors } }) => errors);

  return (
    <div className="email-verification">
      <Card className="email-verification__card">
        <>
          <span className="email-verification__header">{t('welcome_page.welcome_message')}</span>
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit, submitting, values }) => {
              const isButtonDisabled = submitting || !values.recaptcha_token || !values.email;

              return (
                <>
                  {isEmailSent ? (
                    <div className="email-verification__link-send-card">
                      <span className="email-verification__header email-verification__link-send-text">
                        {t('email_verification_page.link_sent_card.title')}
                      </span>
                      <span className="email-verification__link-send-text">
                        <Trans
                          i18nKey="email_verification_page.link_sent_card.description"
                          values={{ email: values.email }}
                          components={{ strong: <strong /> }}
                        />
                      </span>
                      <span>
                        <Trans
                          i18nKey="email_verification_page.link_sent_card.disclaimer"
                          components={{ strong: <strong /> }}
                        />
                      </span>
                    </div>
                  ) : (
                    <form onSubmit={handleSubmit}>
                      <Field name="email">
                        {({ input, meta }) => (
                          <Input
                            size="tiny"
                            className="email-verification__email-input"
                            label={t('user_invite.email.label')}
                            placeholder={t('user_invite.email.placeholder')}
                            fullWidth={true}
                            touched={!meta.dirtySinceLastSubmit}
                            error={meta.submitError}
                            {...input}
                          />
                        )}
                      </Field>
                      <div className="email-verification__info-wrapper">
                        <Icon
                          className="email-verification__info-icon"
                          name="info"
                          size="tiny"
                          color="tertiary"
                          showLabel={true}
                        />
                        <span>{t('email_verification_page.verification_info')}</span>
                      </div>
                      <Field name="recaptcha_token">
                        {({ input, meta }) => (
                          <ReCAPTCHA
                            className="email-verification__recaptcha"
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                            onChange={token => input.onChange(token)}
                            onExpired={() => input.onChange(null)}
                          />
                        )}
                      </Field>
                      <Button
                        label={t('common.continue')}
                        className="email-verification__button"
                        type="success"
                        size="small"
                        disabled={isButtonDisabled}
                        submitType="submit"
                      />
                    </form>
                  )}
                </>
              );
            }}
          />
        </>
      </Card>
    </div>
  );
};

export default EmailVerificationComponent;
