import Icon from 'components/base-components/Icon';
import IconButton from 'components/base-components/IconButton';
import { Header } from 'components/base-components/Typography';
import OrganisationViewHeader from 'components/organisations/view/organisation-view-header.component';
import useNavigationLinks from 'hooks/useNavigationLinks';
import { t } from 'i18next';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import RoleManagmentComponent from './form.component';

const RolesComponent = () => {
  const navigationLinks = useNavigationLinks();
  const { id } = useParams();
  return (
    <div className="user__roles">
      <OrganisationViewHeader />
      <div className="user__roles-header margin-top-20">
        <Link to={navigationLinks.organisationUserPage(id)}>
          <IconButton
            className="organisation__head-popover--go-back"
            icon={<Icon name="goBack" size="small" />}
            color="default"
            size="medium"
            isIconOnly={true}
          />
        </Link>
        <Header weight="boldest" level={6} className="user__roles-header--text">
          {t('user.roles.title')}
        </Header>
      </div>
      <RoleManagmentComponent />
    </div>
  );
};

export default RolesComponent;
