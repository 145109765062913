import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import Icon from '../Icon';
import Tooltip from '../Tooltip';

const CheckBox = props => {
  const {
    label,
    type,
    size,
    disabled,
    id,
    isChecked,
    onChange,
    hint,
    hintPlacement,
    className,
    hintText,
    hintType,
  } = props;

  const createId = useMemo(() => uuidv4(), []);
  const checkBoxID = id || createId;

  const checkboxWrapper = (
    <>
      <input
        name={id}
        className="rcl-checkbox__input"
        id={checkBoxID}
        type={type}
        disabled={disabled}
        checked={isChecked}
        onChange={onChange}
      />
      <span
        className={classNames('rcl-checkbox__check', {
          'rcl-checkbox--checked': isChecked,
        })}
      >
        <Icon className="rcl-checkbox__check-icon" name="check" color="neutral" />
      </span>
    </>
  );

  return (
    <label
      htmlFor={checkBoxID}
      className={classNames('rcl-checkbox', className, {
        'rcl-checkbox--disabled': disabled && !hint,
        'rcl-checkbox--disabled-with-hint': disabled && hint,
        [`rcl-checkbox--${size}`]: size,
        'rcl-checkbox--with-hint-text': hintText,
      })}
    >
      {hint ? (
        <Tooltip
          position={hintPlacement}
          type={hintType}
          content={hint}
          className={`rcl-checkbox__check-tooltip rcl-checkbox__check-tooltip--${size}`}
          showArrow={false}
        >
          {checkboxWrapper}
        </Tooltip>
      ) : (
        checkboxWrapper
      )}
      <div className="rcl-checkbox__label">
        {label && <span className="rcl-checkbox__label--header">{label}</span>}
        {hintText && <span className="rcl-checkbox__label--hint">{hintText}</span>}
      </div>
    </label>
  );
};

CheckBox.defaultProps = {
  label: null,
  size: 'small',
  type: 'checkbox',
  disabled: false,
  isChecked: false,
  onChange: () => {},
  hint: null,
  hintType: 'accent',
  className: null,
  id: null,
  hintPlacement: 'bottom',
  hintText: null,
};

CheckBox.propTypes = {
  label: PropTypes.node,
  size: PropTypes.oneOf(['large', 'huge', 'small', 'tiny']),
  disabled: PropTypes.bool,
  type: PropTypes.string,
  isChecked: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hint: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  hintType: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  hintPlacement: PropTypes.oneOf([
    'left',
    'right',
    'top',
    'bottom',
    'top-left',
    'top-right',
    'bottom-left',
    'bottom-right',
    'left-top',
    'left-bottom',
    'right-top',
    'right-bottom',
  ]),
  hintText: PropTypes.node,
};

export default CheckBox;
