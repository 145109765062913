import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function Header(props) {
  const { children, className, level, weight } = props;

  return (
    <h2 className={classNames('header', className, `header--level-${level}`, `header--${weight}`)}>
      {children}
    </h2>
  );
}

Header.defaultProps = {
  children: null,
  className: null,
  level: 1,
  weight: 'normal',
};

Header.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  level: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  weight: PropTypes.oneOf(['boldest', 'bold', 'medium', 'normal', 'light']),
};

export default Header;
