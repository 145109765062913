import routes from 'utils/routes';
import { apiSlice } from '../index';

const publicRegistrationApi = apiSlice.injectEndpoints({
  endpoints: build => ({
    requestToken: build.mutation({
      query: payload => ({
        url: routes.publicRegistration.requestToken(),
        method: 'POST',
        body: payload,
      }),
    }),
    verifyToken: build.mutation({
      query: token => ({
        url: routes.public.verify(),
        method: 'POST',
        body: { token },
      }),
    }),
  }),
});

export const { useRequestTokenMutation, useVerifyTokenMutation } = publicRegistrationApi;
