import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import camelcaseKeys from 'camelcase-keys';
import * as tagTypes from 'utils/tag-types';
import i18n from '../i18n';

const baseQuery = fetchBaseQuery({
  // TODO: This has to be changed with https://welltravel.atlassian.net/browse/OPS-1270
  baseUrl: `${process.env.REACT_APP_BACKEND_URL}/api/v1`,
  prepareHeaders: headers => {
    headers.set('X-LOCALE', i18n.language);
    return headers;
  },
  credentials: 'include',
});

const customizeBaseQuery = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  const errorCode = result?.error?.status;

  if (errorCode === 403) {
    if (window.location.pathname.startsWith('/public'))
      return window.location = '/public/registration'
    else
      return window.location = '/login?error=unauthenticated';
  } else if (errorCode === 401 && result.error.data?.deactivated) {
    return (window.location = '/login?error=deactivated');
  } else if (errorCode === 401 && !result.error.data?.deactivated) {
    return window.location.replace('/?error=unauthorized');
  } else if (result.data) {
    result.data = camelcaseKeys(result.data, { deep: true }); // function used to transform global response
  }

  const response = result.meta.response;

  if (response && response.headers.get('X-Total') !== null) {
    const pagination = {
      totalPages: response.headers.get('x-Total-Pages'),
      currentPage: response.headers.get('x-Page'),
      totalCount: response.headers.get('X-Total'),
    };

    result.data = { collection: result.data, pagination };
  }

  if (result.error && result.error.data) {
    result = camelcaseKeys(result, { deep: true });
  }

  return result;
};

const allowedTagTypes = Object.values(tagTypes);

export const apiSlice = createApi({
  baseQuery: customizeBaseQuery,
  keepUnusedDataFor: 30,
  refetchOnReconnect: true,
  tagTypes: allowedTagTypes,
  endpoints: () => ({}),
});
